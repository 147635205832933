import { Grid, IconButton, Typography } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { IconTrash } from '@cooperativaobrera/react-icons/tabler-icons';
import useNotificaciones from 'hooks/useNotificaciones';
import { eliminarNotificacion, reaccionarNotificacion } from 'services/NotificacionesService';
import ReaccionButtonComponent from 'components/notificaciones/modal/reacciones/ReaccionButtonComponent';
import { REACCION_DISLIKE, REACCION_LIKE } from 'constants/notificaciones';
import LightTooltip from 'components/lightTooltip/LightTooltipComponent';
import IconoNotificacionesComponent from './IconoNotificacionesComponent';

function NotificacionComponent({ notificacion }) {
  const { state: { notificaciones }, handleModal, obtenerNotificaciones } = useNotificaciones();
  const [reaccionActual, setReaccionActual] = useState(notificacion.reaccion);

  const handleChangeReaccion = (reaccion) => {
    if (reaccion !== reaccionActual) {
      setReaccionActual(reaccion);
      const idReaccion = notificaciones.reacciones[reaccion];
      reaccionarNotificacion(notificacion.id, idReaccion);
    }
  };

  useEffect(() => {
    setReaccionActual(notificacion.reaccion);
  }, [notificacion.reaccion]);

  return (
    <Grid container>
      <Grid 
        xs={9} 
        item
        display="flex" 
        flexDirection="row"
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          handleModal({ open: 'verNotificacion', data: notificacion.id });
        }}
      >
        <Grid xs={1.5} item alignContent="center">
          <IconoNotificacionesComponent tipo={notificacion.tipo} />
        </Grid>
        <Grid item container>
          <Grid xs item>
            <Typography variant="caption" color="text.helper">
              {notificacion.fecha}
            </Typography>
            <Typography variant="body2" gutterBottom sx={{ textWrap: 'auto' }}>
              {notificacion.titulo}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {notificacion.reaccionable && ( 
        <Grid item xs={2} display="flex" alignItems="center" justifyContent="center" gap={1}>
          <ReaccionButtonComponent
            type={REACCION_LIKE} 
            title="Información útil" 
            selected={reaccionActual}
            handleChange={() => handleChangeReaccion(REACCION_LIKE)}
            color="accent"
          />
          <ReaccionButtonComponent
            type={REACCION_DISLIKE} 
            title="Información inútil" 
            selected={reaccionActual}
            handleChange={() => handleChangeReaccion(REACCION_DISLIKE)}
            color="primary"
          />
          <LightTooltip title="Eliminar comunicado">
            <IconButton onClick={() => {
              eliminarNotificacion(notificacion.id);
              obtenerNotificaciones();
            }}
            >
              <IconTrash />
            </IconButton>
          </LightTooltip>
        </Grid>
      )}
    </Grid>
  );
}

export default memo(NotificacionComponent);
