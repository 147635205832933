import {
  IconThumbDown, IconThumbDownFilled, IconThumbUp, IconThumbUpFilled, 
} from '@cooperativaobrera/react-icons/tabler-icons';
import { IconButton } from '@mui/material';
import LightTooltip from 'components/lightTooltip/LightTooltipComponent';

const MAPEO_NOTIFICACION_ICONO = {
  like: (activo, sx) => (activo ? <IconThumbUpFilled sx={sx} /> : <IconThumbUp sx={sx} />),
  dislike: (activo, sx) => (activo ? <IconThumbDownFilled sx={sx} /> : <IconThumbDown sx={sx} />),
};

/**
 * 
 * @param type Tipo de reaccion
 * @param title Titulo del tooltip
 * @param selected Seleccion actual de reaccion
 * @param handleChange Funcion a ejecutarse
 * @param color Color cuando se encuentra activo
 * @returns 
 */
export default function ReaccionButtonComponent({
  type, 
  title, 
  selected, 
  handleChange, 
  color = 'accent', 
  sx = {},
}) {
  const activo = selected === type;
  return (
    <LightTooltip title={title}>
      <IconButton
        color={activo ? color : ''}
        onClick={() => handleChange(type)}
      >
        {MAPEO_NOTIFICACION_ICONO[type](activo, sx)}
      </IconButton>
    </LightTooltip>
  );
}
