import useAlerts from 'hooks/useAlerts';
import { 
  Button, 
  Grid, 
} from '@mui/material';
import { useEffect, useState } from 'react';
import Modal from 'components/modal/Modal2/ModalComponent';
import ModalContent from 'components/modal/Modal2/ModalContentComponent';
import ModalActions from 'components/modal/Modal2/ModalActionsComponent';
import { base64AFile } from 'utils/base64';
import { obtenerNotificacion, reaccionarNotificacion } from 'services/NotificacionesService';
import useNotificaciones from 'hooks/useNotificaciones';
import { useTranslation } from 'react-i18next';
import TemplateAHtmlComponent from './TemplateAHTMLComponent';
import FileDisplayerPrevisualizacion from './FileDisplayerPrevisualizacionComponent';
import ReaccionesNotificacionComponent from './reacciones/ReaccionesNotificacionComponent';

export default function VerNotificacionModalComponent({ open, handleCloseModal, idComunicado }) {
  const { t } = useTranslation();
  const { openSnackbar, startLoading, stopLoading } = useAlerts();
  const [vistaPrevia, setVistaPrevia] = useState('');
  const [archivosAdjuntos, setArchivosAdjuntos] = useState([]);
  const [reaccionable, setReaccionable] = useState([]);
  const { 
    state: { notificaciones }, 
    setNotificaciones, 
    obtenerNotificaciones,
  } = useNotificaciones();
  const [reaccionInicial, setReaccionInicial] = useState('');
  const [reaccionActual, setReaccionActual] = useState('');

  async function getVistaPrevia() {
    const loadingId = startLoading('Cargando notificacion');
    try {
      const res = await obtenerNotificacion(idComunicado);
      setVistaPrevia(res.data.data.html);
      setReaccionInicial(res.data.data.reaccion);
      setReaccionActual(res.data.data.reaccion);
      setReaccionable(res.data.data.reaccionable);

      const adjuntos = await Promise.all(
        res.data.data.adjuntos.map((adjunto, index) => base64AFile(
          adjunto,
          `archivo_adjunto_${index + 1}`,
        )),
      );
      setArchivosAdjuntos(adjuntos);
    } catch (error) {
      handleCloseModal();
      openSnackbar({
        message: 'No se pudo obtener el comunicado en este momento',
        severity: 'error',
      });
    } finally {
      obtenerNotificaciones();
      stopLoading(loadingId);
    }
  }

  const handleChangeReaccion = (type) => {
    setReaccionActual(type);
  };

  const handleClose = () => {
    if (reaccionInicial !== reaccionActual) {
      const idReaccion = notificaciones.reacciones[reaccionActual];
      reaccionarNotificacion(idComunicado, idReaccion);
      const notificacionesLeidasActualizadas = notificaciones.leidas.map((notificacion) => {
        if (notificacion.id === idComunicado) {
          return { ...notificacion, reaccion: reaccionActual };
        }
        return notificacion;
      });

      setNotificaciones({
        ...notificaciones,
        leidas: notificacionesLeidasActualizadas,
      }); 
    }
  
    handleCloseModal();
  };

  useEffect(() => {
    getVistaPrevia();
  }, []);

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      sx={{ 
        sm: { maxWidth: 'calc(100% - 30px)' },
        p: 0,
      }}
    >
      <ModalContent sx={{ borderRadius: '10px', margin: 0 }}>
        <TemplateAHtmlComponent template={vistaPrevia} />
        <Grid sx={{ mx: '20px' }}>
          {Array.isArray(archivosAdjuntos)
            && archivosAdjuntos?.length > 0
            && archivosAdjuntos?.map((archivoAdjunto) => (
              <FileDisplayerPrevisualizacion
                file={archivoAdjunto}
                isImage={false}
                key={archivoAdjunto.name}
              />
            ))}
        </Grid>
      </ModalContent>
      <ModalActions sx={{ padding: '16px', alignItems: 'center' }}>
        <Button onClick={handleClose} variant="outlined">
          {t('pages.inicio.modales.notificaciones.botones.cerrar')}
        </Button>
        {reaccionable && ( 
        <ReaccionesNotificacionComponent 
          handleChangeReaccion={handleChangeReaccion} 
          reaccionActual={reaccionActual}
        />
        )}
      </ModalActions>
    </Modal>
  );
}
