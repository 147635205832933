import obtenerNotificacionesUsuario from 'services/NotificacionesService';
import CreateDataContext from './CreateDataContext';

const notificacionesReducer = (state, action) => {
  switch (action.type) {
    case 'setDrawerState':
      return {
        ...state,
        open: action.payload,
      };
    case 'setNotificaciones':
      return {
        ...state,
        notificaciones: action.payload,
      };
    case 'setModal':
      return {
        ...state,
        modal: action.payload,
      };
    default:
      return state;
  }
};

const obtenerNotificaciones = (dispatch) => async () => {
  const res = await obtenerNotificacionesUsuario();

  if (res.data.success) {
    dispatch({ type: 'setNotificaciones', payload: res.data.data });
  }

  return res;
};

const setNotificaciones = (dispatch) => (notificaciones) => {
  dispatch({ type: 'setNotificaciones', payload: notificaciones });
};

const handleModal = (dispatch) => (modal) => {
  dispatch({ type: 'setModal', payload: modal });
};

const openNotificacionesDrawer = (dispatch) => () => {
  dispatch({ type: 'setDrawerState', payload: true });
};

const closeNotificacionesDrawer = (dispatch) => () => {
  dispatch({ type: 'setDrawerState', payload: false });
};

export const { Context, Provider } = CreateDataContext(
  notificacionesReducer,
  {
    openNotificacionesDrawer,
    closeNotificacionesDrawer,
    obtenerNotificaciones,
    setNotificaciones,
    handleModal,
  },
  {
    open: false,
    notificaciones: {
      nuevas: [],
      leidas: [],
    },
    modal: { open: '', data: '' },
  },
);
