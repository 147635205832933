/* eslint-disable import/no-cycle */
import { setUserId, setUserProperties } from 'firebase/analytics';
import initializeFirebaseService from 'utils/firebase';
import { getPermissionAndToken } from 'utils/firebaseMessaging';
import actualizarToken from 'services/FirebaseService';
import CreateDataContext from './CreateDataContext';

const firebaseContext = (state, action) => {
  switch (action.type) {
    case 'setFirebaseInstance':
      return { ...state, firebaseInstance: action.payload };
    case 'setMessaging':
      return { ...state, messaging: action.payload };
    case 'setNotificacionRecibida':
      return { ...state, notificacionRecibida: true };
    case 'setNotificacionActualizada':
      return { ...state, notificacionRecibida: false };
    default:
      return state;
  }
};

const handleMessage = (dispatch) => (payload) => {
  dispatch({ type: 'setNotificacionRecibida', payload });
};

const initializeFirebase = (dispatch) => async (inicializarServiceWorker, usuario, empleado) => {
  const firebaseInstance = await initializeFirebaseService(
    handleMessage(dispatch), 
    inicializarServiceWorker,
  );
  dispatch({ type: 'setFirebaseInstance', payload: firebaseInstance });

  if (usuario && empleado && firebaseInstance.analytics) {
    setUserId(firebaseInstance.analytics, usuario.id);
    setUserProperties(
      firebaseInstance.analytics,
      {
        legajo: empleado.legajo,
        id_puesto: empleado.id_puesto,
        id_sector: empleado.id_sector,
        id_empresa: empleado.id_empresa,
        id_local: empleado.id_local,
        tipo_usuario: empleado.tipoUsuario,
      },
    );
  }

  if (inicializarServiceWorker) {
    if (firebaseInstance.messaging && typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      const { 
        permissionStatus, 
        currentToken,
      } = await getPermissionAndToken(firebaseInstance.messaging, firebaseInstance.registration);
      if (currentToken) {
        await actualizarToken(currentToken);
      }
      dispatch({ type: 'setMessaging', payload: { permissionStatus, currentToken } });
    }
  }
};

const setNotificacionActualizada = (dispatch) => () => {
  dispatch({ type: 'setNotificacionActualizada' });
};
export const { Context, Provider } = CreateDataContext(
  firebaseContext,
  {
    initializeFirebase,
    setNotificacionActualizada,
  },
  {
    firebaseInstance: null,
    messaging: { permissionStatus: false, currentToken: null },
    notificacion: false,
  },
);
