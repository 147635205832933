import { Grid, Typography } from '@mui/material';
import { REACCION_DISLIKE, REACCION_LIKE } from 'constants/notificaciones';
import { useTranslation } from 'react-i18next';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import ReaccionButtonComponent from './ReaccionButtonComponent';

export default function ReaccionesNotificacionComponent({ reaccionActual, handleChangeReaccion }) {
  const { t } = useTranslation();
  const isMobile = useMobileBreakpoint();
  return (
    <Grid container>
      <Grid item xs display="flex" justifyContent="center" alignItems="center">
        {!isMobile && ( 
          <Typography>{t('pages.inicio.modales.notificaciones.informacion_util')}</Typography>
        )}
        <Grid item xs sx={{ gap: isMobile ? 1 : 0 }} ml={1}>
          <ReaccionButtonComponent 
            type={REACCION_LIKE} 
            title="Sí" 
            selected={reaccionActual}
            handleChange={() => handleChangeReaccion(REACCION_LIKE)}
            sx={{ fontSize: isMobile ? '32px' : '28px' }}
            color="accent"
          />
          <ReaccionButtonComponent 
            type={REACCION_DISLIKE}
            title="No" 
            selected={reaccionActual}
            handleChange={() => handleChangeReaccion(REACCION_DISLIKE)}
            sx={{ fontSize: isMobile ? '32px' : '28px' }}
            color="primary"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
